import { useLocation } from "@reach/router"
import * as React from "react"
import { Helmet } from "react-helmet"

const LandingStructuredData = props => {
  const site_url = process.env.GATSBY_SITE_URL
  let { pathname } = useLocation()
  let ldJson = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Allegiance",
    "alternateName": "Allegiance Real Estate",
    "URL": `${site_url}${pathname}`,
    "Logo": "https://ggfx-allegiance.s3.eu-west-2.amazonaws.com/i.prod/brandlogo_1899d71195.png",
    "description": props?.description,
    // "rera": "23405",
    "numberOfEmployees": "180",
    "foundingDate": "2019-11-17",
    "awards":
      "1st place Damac Properties Awards 2022, 1st place Damac unity awards 2021, oxe the best partner award 2022, 1st place Damac Broker Awards 2023 ",
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "+971 5 641 44147",
      "areaServed": "Dubai",
      "email": "info@allegiance.ae",
      "availableLanguage": [
        "Arabic",
        "English",
        "Russian",
        "French",
        "German",
        "Spanish",
        "Portuguese",
        "Uzbek",
        "Ukrainian",
        "Ukrainian",
        "Urdu",
        "Italian",
        "Catalan",
        "Turkish",
        "Japanese",
        "Dutch",
        "Farsi",
        "Chinese",
      ]
    },
    "dateModified": props?.dateModified,
    "address": {
      "@type": "PostalAddress",
      "addressLocality": "Dubai, UAE",
      "postalCode": "00000",
      "streetAddress": "Suite 2804, CONTROL TOWER - Motor City",
      "telephone": "+971 5 641 44147",
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.9",
      "reviewCount": "5",
    },
    "sameAs": [
      "https://www.facebook.com/allegiance.ae",
      "https://www.linkedin.com/company/allegiance-real-estate/",
      "https://www.youtube.com/channel/UCluQT0ROUpAHg0IA0473kGw",
      "https://www.instagram.com/allegiance.ae/",
    ],
  }

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(ldJson, null, 2)}
      </script>
    </Helmet>
  )
}
export default LandingStructuredData
