import React from "react"
import StepCard from "./StepCard"
import "./StaticStepsModule.scss"

const StaticStepsModule = ({ module }) => {
  if (!module?.contents) return null

  return (
    <div className="static-steps-module-wrap">
      {module.contents.map((step, index) => (
        <StepCard key={index} step={step} stepNo={index + 1} />
      ))}
    </div>
  )
}

export default StaticStepsModule
