import React from "react"
import parse from "html-react-parser"
import { Container } from "react-bootstrap"
import { Link } from "gatsby"
import { GetPageURL } from "../common/site/utils"
import "./BannerStatic.scss"

const BannerStatic = ({ bannerData }) => {
  return (
    <div className="static-detail-banner">
      <Container>
        <div className="content-landing">
          <div className="content">
            <h1 className="title">{bannerData.title}</h1>
            {bannerData.content && (
              <p className="static">{parse(bannerData.content)}</p>
            )}
            {bannerData.button_cta_1 || bannerData.button_cta_2 ? (
              <div className="cta-section">
                {bannerData.button_cta_1 && (
                  <Link
                    to={GetPageURL(
                      bannerData.button_cta_1.menu_item,
                      bannerData.button_cta_1.custom_link
                    )}
                    className="cta-link cta-link-black"
                  >
                    {bannerData.button_cta_1.label}
                  </Link>
                )}
                {bannerData.button_cta_2 && (
                  <Link
                    to={GetPageURL(
                      bannerData.button_cta_2.menu_item,
                      bannerData.button_cta_2.custom_link
                    )}
                    className="cta-link cta-link-black"
                  >
                    {bannerData.button_cta_2.label}
                  </Link>
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default BannerStatic
