import React, { useState, useEffect } from "react"
import { Container } from "react-bootstrap"
import "./ReviewListing.scss"
import parse from "html-react-parser"
import { graphql, useStaticQuery } from "gatsby"
import axios from "axios"
import ReviewCard from "../ReviewCard/ReviewCard"
import usePagination from "../../hooks/usePagination"
import Pagination from "../Pagination/Pagination"
// import { useAutoAnimate } from "@formkit/auto-animate/react"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import "./ReviewListing.scss"

const ReviewListing = ({ module }) => {
  const [reviewData, setReviewData] = useState([])
  const [loading, setloading] = useState(false)
  const token = process.env.GATSBY_STRAPI_FORM_TOKEN

  // const [parent] = useAutoAnimate({ duration: 300 })
  const { isMobile, isTablet, isLargeScreenOnly } = useDeviceMedia()

  const itemsPerPage = isMobile ? 10 : isTablet || isLargeScreenOnly ? 14 : 25
  const { currentItems, currentPage, setCurrentPage } = usePagination({
    items: reviewData,
    itemsPerPage,
  })

  const getitems = async url => {
    try {
      const { data } = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          // "Content-Type": "multipart/form-data"
        },
      }) // could be from env files
      setReviewData(data?.slice(0, 25))
      setloading(false)
    } catch (error) {
      // cache it if fail/error;
      console.error(error)
      setloading(false)
    }
  }
  useEffect(() => {
    setloading(true)
    let url = `${process.env.GATSBY_STRAPI_SRC}/stb-google-reviews/google-review-reviews`
    getitems(url)
  }, [])


  return (
    <div className="review-listing-wrapper">
      <Container>
        <div className="review-list-section">
          <div className="content">{parse(module.description)}</div>
          {currentItems.length > 0 && (
            <div className="review-list-items" >
              {currentItems.map(items => (
                <>
                  {items.starRating !== "ONE" && items.starRating !== "TWO" && items.starRating !== "THREE" &&
                    <ReviewCard review={items} />
                  }
                </>
              ))}
            </div>
          )}
          <Pagination
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            totalItems={reviewData.length}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </Container>
    </div>
  )
}

export default ReviewListing
