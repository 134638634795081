import React from "react"
import { Container } from "react-bootstrap"
import "./StatsComponent.scss"
import {
  inViewOptions2,
  containerVariants,
  fadeInFromTop,
} from "../utils/animation"

import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"

const StatsComponent = ({ module,GQLPage }) => {
  const { title, stats } = module
  const pageName=GQLPage.title==="why choose allegiance?"?"why-choose":""
  return (
    <InView {...inViewOptions2}>
      {({ ref, inView }) => (
        <motion.div
          className="animation-component"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}
        >
          <motion.div
            className="stats-component-wrapper"
            variants={fadeInFromTop}
          >
            <Container>
              {GQLPage.title==="why choose allegiance?"&&
                <h2 className="choose-allegiance-page">Why allegiance</h2>
              }
              {GQLPage.title==="why choose allegiance?" ? 
              <p className={`stats-component-title ${pageName}`}>{title}</p>
              : <h2 className={`stats-component-title ${pageName}`}>{title}</h2>}
              <div className="stats-list-items">
                {stats.map(stat => (
                  <div className="stat-list">
                    <div className={`icon ${stat.icon_class}`}></div>
                    <h3 className="stat-heading">{stat.heading}</h3>
                    <p>{stat.content}</p>
                  </div>
                ))}
              </div>
            </Container>
          </motion.div>
        </motion.div>
      )}
    </InView>
  )
}

export default StatsComponent
