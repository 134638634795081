import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import NewsCard from "../NewsCard/NewsCard"
import SliderModule from "../SliderModule/SliderModule"
import TeamCard from "../TeamCard/TeamCard"
import "./TeamSlider.scss"

const TeamSlider = ({ module }) => {
  const data = useStaticQuery(graphql`
    query getTeamSliderData {
      glstrapi {
        teams(sort: "sort_order",where: {publish:true }) {
          ...teamData
        }
      }
    }
  `)

  const teams = data.glstrapi.teams?.slice(0,7);

  const sliderData = teams.map(team => (
    <TeamCard team={team} contentLeftAlign />
  ))

  return (
    <div className="areaguide-slider-module">
      <SliderModule
        title={module.title}
        description={module.description}
        sliderData={sliderData}
        className="areaguide-slider-wrap"
        sliderClassName="areaguide-slider-section"
        theme={module.theme}
        size="lg"
      />
    </div>
  )
}

export default TeamSlider
