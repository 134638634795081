import React from "react"
import parse from "html-react-parser"
import "./StaticRickText.scss"

const StaticRickText = ({ module }) => {
  if (!module.text) return null

  return <div className="static-text-wrap">{parse(module.text)}</div>
}

export default StaticRickText
