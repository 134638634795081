import React from "react"
import { Container } from "react-bootstrap"
import AwardSlider from "../AwardSlider/AwardSlider"
import "../ContentWIthStatsModule/ContentWIthStatsModule.scss"
import {
  inViewOptions2,
  containerVariants,
  fadeInFromTop,
} from "../utils/animation"

import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"

const AwardComponent = ({ module }) => {
  const { theme } = module
  return (
    <InView {...inViewOptions2}>
      {({ ref, inView }) => (
        <motion.div
          className="animation-component"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}
        >
          <motion.div
            className={`awardcomponent-wrapper ${theme}`}
            variants={fadeInFromTop}
          >
            <Container>
              <AwardSlider module={module} />
            </Container>
          </motion.div>
        </motion.div>
      )}
    </InView>
  )
}

export default AwardComponent
