import React from "react"
import parse from "html-react-parser"

const StepCard = ({ step, stepNo }) => {
  return (
    <div className="static-step-card">
      <div className="step-number-section">
        <p className="step-text">step</p>
        <p className="step-text-number">{stepNo}</p>
      </div>
      <div className="step-content-section">
        <p className="step-title">{step.title}</p>
        {step.description && (
          <div className="step-description">{parse(step.description)}</div>
        )}
      </div>
    </div>
  )
}

export default StepCard
