import _ from "lodash"
import React from "react"
import { Accordion, Container } from "react-bootstrap"
import parse from "html-react-parser"
import "./FAQModule.scss"

const FAQModule = ({ module }) => {
  if (!module) return ""

  return (
    <div className="faq-module-wrapper section-p80">
      <Container className="faq-module-container">
        <h3 className="title">{module.title}</h3>
        {!_.isEmpty(module.faq) && (
          <div className="faq-section">
            <Accordion flush>
              {module.faq?.map((accordion, i) => (
                <Accordion.Item eventKey={i} key={"acoordian" + i}>
                  <Accordion.Header>
                    <h4>{accordion.question}</h4>
                  </Accordion.Header>
                  <Accordion.Body>{parse(accordion.answer)}</Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
        )}
      </Container>
    </div>
  )
}

export default FAQModule
