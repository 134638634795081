module.exports.SchemaDesc = {
  BuyingWithAllegiance: `Allegiance: Your Premier Global Real Estate Partner in Dubai. With over 20 billion dirhams in property sales, we're your go-to source for making informed real estate decisions. Ranked first by Damac Properties and acclaimed by developers like Sobha, Emaar, and more. Find your dream home in Dubai's thriving neighborhoods, backed by a stable political climate and a booming economy. Enjoy the benefits of a tax-free status and a vibrant tourism industry. Explore luxurious high-rise apartments, villas, and mansions, all accompanied by world-class amenities. Allegiance streamlines the buying process for an effortless experience. Secure your property today!`,
  DuabiCommunities: `Discover Dubai Communities: Explore Diverse Neighborhoods for Your Ideal Urban or Suburban Experience.`,
  SellingWithAllegiance: `Unlock Property Listing Success with Allegiance. Expert pricing, professional marketing, and seamless legal guidance for a hassle-free selling experience. List with us to maximize your property's value and simplify the sales process.`,
  RentingWithAllegiance: `Seamless Property Rental Process in Dubai: Whether Renting Your Property or Seeking a Rental, Allegiance Supports You at Every Step. From setting a competitive rental price to tenant screening, or finding your ideal rental property, we've got you covered.`,
  BuyersGuide: `Step-by-Step Property Purchase Guide in Dubai: Budgeting, Pre-Approval, and Ownership Transfer. Get expert advice with Allegiance Real Estate.`,
  SellersGuide: `Streamlined Property Selling Process: From Presentation to Ownership Transfer. Maximize value, market effectively, and navigate the sale with Allegiance Real Estate.`,
  TenantsGuide: `Our tenants guide is designed to help you navigate the process of renting a property in Dubai. We cover everything from finding the right property to negotiating the terms of your lease.`,
  TenantsFaqs: `Have questions about renting a property in Dubai? Check out our tenants FAQs for answers to commonly asked questions. Our team of experts is also available to provide you with personalized guidance.`,
  FutureRoadshow: `Stay updated on Dubai property expo happening around the world and get access to the latest real estate opportunities in Dubai. Allegiance roadshows are the perfect platform to connect with potential buyers, sellers, and investors. Join our Dubai Property Expo today.`,
  PreviousRoadshow: `Missed our previous roadshows? Don't worry, you can still access the opportunities presented. Contact us to learn more about the properties showcased in our previous roadshows.`,
  WhyAttendRoadshow: `Our roadshows offer a unique opportunity to connect with potential buyers, sellers, and investors in Dubai. Attend our roadshows and get access to exclusive real estate opportunities, expert guidance, and networking opportunities.`,
  FinancingProperty: `Need financing for your real estate investment in Dubai? Allegiance offers a wide range of financing solutions, tailored to meet your unique needs. Our team of experts will help you secure the best possible financing terms.`,
  InvestmentAdvisory: `Looking to invest in real estate in Dubai? Our investment advisory services will help you identify the best investment opportunities and maximize your returns. We provide expert guidance throughout the investment process.`,
  PropertyManagement: `Let Allegiance take care of your property management needs in Dubai. Our team of experts will ensure that your property is well-maintained, maximizing your returns and minimizing your hassles.`,
  PropertyValuation: `Discover your Dubai property's true value with precise valuation services. Expert appraisers use market, cost, and income approaches for accurate assessments, crucial for buying, selling, and financial decisions.`,
  InteriorDesign: `Transform your property into a stunning masterpiece with Allegiance's interior designing services. Our team of expert designers will work with you to create a space that reflects your personal style and meets your functional needs.`,
  WhyAllegiance: `Choose Allegiance for all your real estate needs in Dubai. We offer a wide range of services, including buying, selling, renting, property management, and interior designing, all backed by a team of experts who are dedicated to your satisfaction.`,
  ClientReviews: `Don't take our word for it - read what our clients have to say about their experiences with Allegiance. Our client reviews speak to our commitment to excellence and our dedication to providing exceptional service.`,
  TeamPage: `Get to know the experts behind Allegiance. Our team of professionals has extensive knowledge of the Dubai real estate market and is committed to providing you with the best possible service.`,
  Careers: `Looking for a rewarding career in real estate? Join the Allegiance team and become part of a dynamic and innovative company that is committed to excellence and growth.`,
  OurPartners: `Allegiance works with a network of trusted partners to provide our clients with the best possible service. Learn more about our partners and how they help us deliver exceptional real estate solutions in Dubai.`,
  Media: `Stay up-to-date with the latest news and updates from Allegiance. Our media section features news, press releases, and other relevant information about our company and the Dubai real estate market.`,
  Contact: `Get in touch with Allegiance for all your real estate needs in Dubai. Our team of experts is here to help you with buying, selling, renting, property management, and interior designing. Contact us today to learn more.`,
}

module.exports.SchemaAttribute = {
  date: "2024-01-10T12:30:00-05:00"
}