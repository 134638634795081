import { graphql, useStaticQuery } from "gatsby"
import React, { useEffect, useState } from "react"
import { Tab, Tabs } from "../CustomTabs/CustomTabs"
import { Container } from "react-bootstrap"
import "./RoadshowsListing.scss"
import RoadshowCard from "../RoadshowCard/RoadshowCard"
import usePagination from "../../hooks/usePagination"
import Pagination from "../Pagination/Pagination"
// import { useAutoAnimate } from "@formkit/auto-animate/react"
import {
  inViewOptions2,
  containerVariants,
  fadeInFromTop,
} from "../utils/animation"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"

const CATEGORIES = [
  {
    label: "Upcoming",
    value: "upcoming",
  },
  {
    label: "Previous",
    value: "previous",
  },
]

const CATEGORIES1 = [
  {
    label: "Previous",
    value: "previous",
  },
]

const RoadshowsListing = ({ slug }) => {
  const data = useStaticQuery(graphql`
    query RoadshowsListing {
      glstrapi {
        roadShows(sort: "from_date:DESC") {
          ...roadShowDetails
        }
      }
    }
  `)

  // for layout animation
  // const [parent] = useAutoAnimate({ duration: 300 })

  const roadShows = data?.glstrapi?.roadShows
  let upcomingRoadShows = roadShows.filter(
    roadShow => new Date(roadShow.from_date).getTime() > new Date().getTime()
  )

upcomingRoadShows=upcomingRoadShows.sort((a,b)=>{
  return new Date(a.from_date).getTime()-new Date(b.from_date).getTime()
}
)

  let previousRoadShows = roadShows.filter(
    roadShow => new Date(roadShow.from_date).getTime() < new Date().getTime()
  )

  previousRoadShows=previousRoadShows.sort((a,b)=>{
    return new Date(a.from_date).getTime() + new Date(b.from_date).getTime()
  
  })

  let isPrevious = slug === 'previous'
  const [activeKey, setActiveKey] = useState(isPrevious ? CATEGORIES[1].value : CATEGORIES[0].value)
  // const [activeKey, setActiveKey] = useState(isPrevious ? CATEGORIES[1].value :upcomingRoadShows?.length>0? CATEGORIES[0].value: CATEGORIES[1].value)

  const [selectedRoadShows, setSelectedRoadShows] = useState(upcomingRoadShows)

  // for pagination
  const itemsPerPage = 12
  const { currentItems, currentPage, setCurrentPage } = usePagination({
    items: selectedRoadShows,
    itemsPerPage,
  })
  // for pagination

  useEffect(() => {
    if (activeKey === "upcoming") {
      setSelectedRoadShows(upcomingRoadShows)
    } else {
      setSelectedRoadShows(previousRoadShows)
    }
    setCurrentPage(1)
  }, [activeKey])


  return (
    <InView {...inViewOptions2}>
      {({ ref, inView }) => (
        <motion.div
          className="animation-component"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}
        >
          <motion.div
            className="roadshows-listing-wrap"
            variants={fadeInFromTop}
          >
            <Container className="roadshows-listing-container">
              <div className="roadshow-category-section-wrap">
                <Tabs
                  className="roadshow-category-section"
                  activeKey={activeKey}
                  setActiveKey={setActiveKey}
                  darkTheme
                >
                  {upcomingRoadShows?.length>0&&CATEGORIES.map(category => (
                    <Tab eventKey={category.value}>{category.label}</Tab>
                  ))}
                </Tabs>
              </div>
              <div className="roadshows-listing" >
                {currentItems.map(roadShow => (
                  <RoadshowCard roadshow={roadShow} key={roadShow.id} />
                ))}
              </div>
              <Pagination
                currentPage={currentPage}
                itemsPerPage={itemsPerPage}
                totalItems={selectedRoadShows.length}
                setCurrentPage={setCurrentPage}
              />
            </Container>
          </motion.div>
        </motion.div>
      )}
    </InView>
  )
}

export default RoadshowsListing
