import React from "react"
import { Container } from "react-bootstrap"
import parse from "html-react-parser"
import "./TextContentSlider.scss"
import CustomSlider from "../CustomSlider/CustomSlider"
import {
  inViewOptions2,
  containerVariants,
  fadeInFromTop,
} from "../utils/animation"

import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"

const TextContentSlider = ({ module }) => {
  const { title, description, slider_content } = module
  return (
    <InView {...inViewOptions2}>
      {({ ref, inView }) => (
        <motion.div
          className="animation-component"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}
        >
          <div className="text-content-slider-wrapper">
            <Container>
              <motion.div
                className="text-content-slider"
                variants={fadeInFromTop}
              >
                <h2 className="text-slider-title">{title}</h2>
                <p className="text-slider-description">{parse(description)}</p>
                <CustomSlider className="text-content_slider" length={slider_content?.length}>
                  {slider_content.map(slider => (
                    <div className="text-slider-list">
                      <div className="image-section">
                        <img src={slider.image?.url} alt={slider.title} />
                      </div>
                      <div className="text-slider-text">
                        <h3>{slider.title}</h3>
                        <p>{parse(slider.description)}</p>
                      </div>
                    </div>
                  ))}
                </CustomSlider>
              </motion.div>
            </Container>
          </div>
        </motion.div>
      )}
    </InView>
  )
}

export default TextContentSlider
