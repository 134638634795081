import React, { Fragment } from "react"
import AreaguideSlider from "../components/AreaguideSlider/AreaguideSlider"
import BannerLanding from "../components/BannerLanding/BannerLanding"
import BannerStatic from "../components/BannerStatic/BannerStatic"
import ContentWIthStatsModule from "../components/ContentWIthStatsModule/ContentWIthStatsModule"
import FeaturedProperties from "../components/FeaturedProperties/FeaturedProperties"
import FormContentComponent from "../components/FormContentComponent/FormContentComponent"
import Layout from "../components/Layout/Layout"
import ListingBanner from "../components/ListingBanner/ListingBanner"
import ListingBannerNoImg from "../components/ListingBannerNoImg/ListingBannerNoImg"
import TeamListing from "../components/TeamListing/TeamListing"
import NewsListing from "../components/NewsListing/NewsListing"
import NewsSlider from "../components/NewsSlider/NewsSlider"
import UpComingRoadShows from "../components/UpComingRoadShow/UpComingRoadShows"
import TeamSlider from "../components/TeamSlider/TeamSlider"
import AreaGuideListing from "../components/AreaGuideListing/AreaGuideListing"
import FAQModule from "../components/FAQ/FAQModule"
import StatsComponent from "../components/StatsComponent/StatsComponent"
import TextContentSlider from "../components/TextContentSlider/TextContentSlider"
import JobOpenings from "../components/JobOpenings/JobOpenings"
import GlobalFormComponent from "../components/GlobalFormComponent/GlobalFormComponent"
import AwardComponent from "../components/AwardComponent/AwardComponent"
import MortgageCalculator from "../components/MortgageCalculator/MortgageCalculator"
import OurPartners from "../components/OurPartners/OurPartners"
import RoadshowsListing from "../components/RoadshowsListing/RoadshowsListing"
import ReviewListing from "../components/ReviewListing/ReviewListing"
import balckPhoneImg from "../images/black-phone.svg"
import whatsappImg from "../images/whatsapp.svg"
import { getWhatsAppURL } from "../components/common/site/utils"
import useDeviceMedia from "../hooks/useDeviceMedia"
import useCompanyInfo from "../hooks/useCompanyInfo"
import MobileContact from "../components/MobileContact/MobileContact"
import AboutStructuredData from "../components/Seo/AboutStructuredData"
import LandingStructuredData from "../components/Seo/LandingStructuredData"
import { SchemaAttribute, SchemaDesc } from "../site/seoschema"

const CommonTemplate = props => {
  const {
    GQLPage,
    GQLModules,
    locationState,
    pageLayout,
    Header_Type,
    page_class,
  } = props

  const bannerData = GQLPage.banner_section

  const {isLargeScreen,isMobile}=useDeviceMedia()
  const { phone } = useCompanyInfo()
  const schemaDescriptions = {
    'buying-with-allegiance': SchemaDesc.BuyingWithAllegiance,
    'dubai-communities': SchemaDesc.DuabiCommunities,
    'property-selling-with-allegiance': SchemaDesc.SellingWithAllegiance,
    'property-renting-with-allegiance': SchemaDesc.RentingWithAllegiance,
    'tenants-guide': SchemaDesc.TenantsGuide,
    'tenants-faqs': SchemaDesc.TenantsFaqs,
    'future': SchemaDesc.FutureRoadshow,
    'previous': SchemaDesc.PreviousRoadshow,
    'why-attend-allegiance-roadshows': SchemaDesc.WhyAttendRoadshow,
    'financing-property': SchemaDesc.FinancingProperty,
    'investment-advisory': SchemaDesc.InvestmentAdvisory,
    'property-management': SchemaDesc.PropertyManagement,
    'property-valuation': SchemaDesc.PropertyValuation,
    'interior-designing': SchemaDesc.InteriorDesign,
    'client-reviews': SchemaDesc.ClientReviews,
    'meet-the-team': SchemaDesc.TeamPage,
    'careers': SchemaDesc.Careers,
    'our-partners': SchemaDesc.OurPartners,
    'media': SchemaDesc.Media
  };

  const dateModifiedObj = {
    'future': SchemaAttribute.date,
    'previous': SchemaAttribute.date,
    'client-reviews': SchemaAttribute.date,
    'media': SchemaAttribute.date
  }
  const description = schemaDescriptions[GQLPage?.menu?.slug] || '';
  const dateModified = dateModifiedObj[GQLPage?.menu?.slug] || ''

  return (
    <Layout pageLayout={pageLayout} Header_Type={Header_Type} popularSearch={GQLPage?.Popular_Search}>
      {!isLargeScreen&&
       <div className="social-section">
          <a href={getWhatsAppURL(phone)} target="_blank">
            <img
              src={whatsappImg}
              alt="social-whatsapp"
              className="social-icon social-whatsapp"
            />
          </a>
          <a href={"tel:" + phone}>
            <img
              src={balckPhoneImg}
              alt="social-phone"
              className="social-icon social-phone"
            />
          </a>
        </div>
        }

        {
          isMobile&&
          <MobileContact/>
        }

      {pageLayout === "landing_template" && (
        <BannerLanding bannerData={bannerData} page_class={page_class} GQLPage={GQLPage} Header_Type={Header_Type}/>
      )}
      {pageLayout === "static_template" && (
        <BannerStatic bannerData={bannerData} />
      )}

      {pageLayout === "listing_template" &&
        (bannerData.banner_image ? (
          <ListingBanner bannerData={bannerData} page_class={page_class} GQLPage={GQLPage} Header_Type={Header_Type}/>
        ) : (
          <ListingBannerNoImg bannerData={bannerData} page_class={page_class} GQLPage={GQLPage}/>
        ))} 
      {GQLModules.map(module => {
        return (
          <Fragment key={module.id}>
            {GQLPage?.menu?.slug === "why-allegiance" ? <AboutStructuredData /> : <LandingStructuredData description={description} dateModified={dateModified}/>}
            {module.__typename ===
              "GLSTRAPI_ComponentModulesFeaturedProperties" && (
              <FeaturedProperties module={module} />
            )}

            {module.__typename ===
              "GLSTRAPI_ComponentModulesContentWithStats" && (
              <ContentWIthStatsModule module={module} />
            )}

            {module.__typename ===
              "GLSTRAPI_ComponentComponentsSelectCollection" &&
              module.choose_collection === "areaguide_slider" && (
                <AreaguideSlider module={module} />
              )}

            {module.__typename ===
              "GLSTRAPI_ComponentComponentsSelectCollection" &&
              module.choose_collection === "areaguide_listing" && (
                <AreaGuideListing module={module} />
              )}

            {module.__typename ===
              "GLSTRAPI_ComponentComponentsSelectCollection" &&
              module.choose_collection === "news_slider" && (
                <NewsSlider module={module} />
              )}
            {module.__typename ===
              "GLSTRAPI_ComponentComponentsSelectCollection" &&
              module.choose_collection === "awards_slider" && (
                <AwardComponent module={module} />
              )}

            {module.__typename ===
              "GLSTRAPI_ComponentComponentsSelectCollection" &&
              module.choose_collection === "mortgage_calculator_module" && (
                <MortgageCalculator module={module} />
              )}

            {module.__typename ===
              "GLSTRAPI_ComponentComponentsSelectCollection" &&
              module.choose_collection === "team_slider" && (
                <TeamSlider module={module} />
              )}

            {module.__typename ===
              "GLSTRAPI_ComponentComponentsSelectCollection" &&
              module.choose_collection === "team_listing" && (
                <TeamListing module={module} />
              )}
            {module.__typename ===
              "GLSTRAPI_ComponentComponentsSelectCollection" &&
              module.choose_collection === "customer_reviews_listing" && (
                <ReviewListing module={module} />
              )}

            {module.__typename ===
              "GLSTRAPI_ComponentComponentsSelectCollection" &&
              module.choose_collection === "partners_listing" && (
                <OurPartners module={module} />
              )}
            {module.__typename ===
              "GLSTRAPI_ComponentComponentsSelectCollection" &&
              module.choose_collection === "roadshows_listing" && (
                <RoadshowsListing module={module} slug={GQLPage?.menu?.slug} />
              )}

            {module.__typename ===
              "GLSTRAPI_ComponentComponentsSelectCollection" &&
              module.choose_collection === "news_listing" && <NewsListing />}

            {module.__typename ===
              "GLSTRAPI_ComponentComponentsSelectModules" &&
              module.select_module === "upcoming_roadshows" && (
                <UpComingRoadShows isModule={true}/>
              )}

            {module.__typename ===
              "GLSTRAPI_ComponentComponentsSelectCollection" &&
              module.choose_collection === "job_openings" && (
                <JobOpenings module={module} />
              )}

            {module.__typename ===
              "GLSTRAPI_ComponentModulesGuideDownloadModule" && (
              <FormContentComponent module={module} alias={GQLPage.alias}/>
            )}
            {module.__typename === "GLSTRAPI_ComponentComponentsFaq" && GQLPage.page_class==="tenants-faq-page"&& (
              <FAQModule module={module} />
            )}
            {module.__typename === "GLSTRAPI_ComponentModulesStatsModule" && (
              <StatsComponent module={module} GQLPage={GQLPage}/>
            )}
            {module.__typename ===
              "GLSTRAPI_ComponentModulesTextContentsSlider" && (
              <TextContentSlider module={module} />
            )}
            {module.__typename === "GLSTRAPI_ComponentComponentsSelectForm" &&
              module.select_form === "careers_send_cv" && (
                <GlobalFormComponent module={module} formName="career" />
              )}
          </Fragment>
        )
      })}
    </Layout>
  )
}

export default CommonTemplate
