import React from "react"
import GetReview from "../GetReview/GetReview"

const ReviewCard = ({ review }) => {
  const { reviewer, starRating, comment, updateTime } = review

  const date1 = new Date()
  const date2 = new Date(updateTime)

  var diff = Math.floor(date1.getTime() - date2.getTime())
  var day = 1000 * 60 * 60 * 24

  var days = Math.floor(diff / day)
  var months = Math.floor(days / 31)
  var years = Math.floor(months / 12)

  const reviewWritten =
    years !== 0
      ? years + " years ago"
      : months !== 0
      ? months + " months ago"
      : days + " days ago"

  return (
    <div className="review-card-wrap">
      <GetReview starRating={starRating} />
      <div className="review-datas">
        <div className="comment">
          {comment?.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, "")}
        </div>
        <div className="reviewer">
          <img src={reviewer?.profilePhotoUrl} alt={reviewer?.displayName} />
          <div className="name-section">
            <p className="name">{reviewer?.displayName}</p>
            <p className="time">{reviewWritten}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReviewCard
