/**
 * useCategory is a custom hook that handles category selection and filtering.
 * @param {Object} categories - the list of categories to use
 * @param {string} allCategoryLabel - the label to use for the "all" category
 * @returns {Object} - an object containing the category options, a function to handle category change, and the selected category
 */

import { useState } from "react"

const useCategorySelect = ({
  categories,
  allCategoryLabel,
  categoriesWithNoItems,
  hideAllCategory,
  customHandleCategoryChange,
}) => {
  // Create a new category option to represent the "All" category.
  const allCategoryOption = {
    label: allCategoryLabel || "All",
    value: "all",
  }

  // Create a new state variable to hold the selected category.
  const [selectedCategory, setSelectedCategory] = useState(allCategoryOption)

  // Create a new array of category options for the Select component.
  const categoryOptions = categories.map(category => ({
    label: category.name,
    value: category.slug,
  }))

  // Remove any categories that have no items.
  categoriesWithNoItems?.forEach(category => {
    // Find the index of the category that matches the slug
    const index = categoryOptions.findIndex(
      option => option.value === category.slug
    )

    // Remove the category from the categoryOptions array at the index found above
    categoryOptions.splice(index, 1)
  })

  // Add the "All" category option to the list of category options.
  categoryOptions.unshift(allCategoryOption)

  // Create a new handler function that will be called when the user changes the selected category.
  const handleCategoryChange = selectedOption => {
    setSelectedCategory(selectedOption)
  }

  // Remove the "All" category option if hideAllCategory is true.
  if (hideAllCategory) {
    // Find the index of the "All" category option.
    const index = categoryOptions.findIndex(option => option.value === "all")

    // Remove the "All" category option from the categoryOptions array at the index found above.
    categoryOptions.splice(index, 1)
  }

  // if customHandleCategoryChange is passed in, add the custom function to the handleCategoryChange function
  if (customHandleCategoryChange) {
    handleCategoryChange = selectedOption => {
      setSelectedCategory(selectedOption)
      customHandleCategoryChange(selectedOption)
    }
  }

  // Return the new state variables and functions to the component.
  return {
    categoryOptions,
    handleCategoryChange,
    selectedCategory,
  }
}

export default useCategorySelect
