import React, { useState } from "react"
import { Container } from "react-bootstrap"
import parse from "html-react-parser"
import { graphql, Link, useStaticQuery } from "gatsby"
import "./OurPartners.scss"
import Modal from "react-bootstrap/Modal"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import {
  inViewOptions2,
  containerVariants,
  fadeInFromTop,
} from "../utils/animation"

import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
import { contactURL, defaultOffplanURL, propertyForSaleURL } from "../../site/urls"

const OurPartners = ({ module }) => {
  const { isTabletOnly } = useDeviceMedia()
  const [show, setShow] = useState(false)

  const [modalData, setModalData] = useState(null)
  const data = useStaticQuery(graphql`
    query getPartners {
      glstrapi {
        partners {
          id
          description
          image {
            url
          }
          name
          slug
        }
      }
    }
  `)
  const { description } = module
  const partnersData = data.glstrapi.partners

  const showModal = data => {
    setShow(true)
    setModalData(data)
  }
  const handleClose = () => {
    setShow(false)
    setModalData(null)
  }
  return (
    <InView {...inViewOptions2}>
      {({ ref, inView }) => (
        <motion.div
          className="animation-component"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}
        >
          <div className="our-partners-wrapper">
            <Container>
              <motion.div
                className="our-partners-section"
                variants={fadeInFromTop}
              >
                {description && (
                  <div className="our-partner-content">
                    {parse(description)}
                  </div>
                )}
                {partnersData.length > 0 && (
                  <div className="partners-list">
                    {partnersData.map(data => (
                      <div
                        className="partner-image"
                        onClick={() => showModal(data)}
                      >
                        <img src={data.image.url} alt={data.name} />
                      </div>
                    ))}
                  </div>
                )}
              </motion.div>
              {
                <Modal
                  show={show}
                  centered={true}
                  onHide={handleClose}
                  backdrop="static"
                  keyboard={false}
                  dialogClassName="modal-90w our-partners"
                >
                  <Modal.Body className="form">
                    <div>
                      <div
                        className={`${
                          isTabletOnly
                            ? "modal-cancel_button tablet"
                            : "modal-cancel_button"
                        }`}
                      >
                        {isTabletOnly ? (
                          <i
                            className={`icon cancel-icon`}
                            onClick={() => handleClose()}
                          />
                        ) : (
                          <i
                            className={`icon cancel-icon`}
                            onClick={() => handleClose()}
                          />
                        )}
                      </div>
                      <div className="partner-modal-image">
                        <img
                          src={modalData?.image.url}
                          alt="partner-modal-image"
                        />
                      </div>
                      <div className="partner-modal-content">
                        <p>{modalData?.description}</p>
                        <div className="partners-ctas">

                          
                        {modalData?.slug?
                          <Link to={`${defaultOffplanURL}developed-by-${modalData.slug}/`}>
                            <button className="button button-black">
                              view Developments
                            </button>
                          </Link>
                          :
                          <Link to={`${defaultOffplanURL}`}>
                            <button className="button button-black">
                              view Developments
                            </button>
                          </Link>
                        }
                          <Link to={`${contactURL}`}>
                            <button className="button button-black-outline">
                              Contact our team
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              }
            </Container>
          </div>
        </motion.div>
      )}
    </InView>
  )
}

export default OurPartners
