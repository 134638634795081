import { graphql, useStaticQuery } from "gatsby"
import React, { useEffect, useState } from "react"
import { Container } from "react-bootstrap"
import TeamCard from "../TeamCard/TeamCard"
import parse from "html-react-parser"
import "./TeamListing.scss"
import useCategorySelect from "../../hooks/useCategorySelect"
import CategorySelect from "../CategorySelect/CategorySelect"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import usePagination from "../../hooks/usePagination"
import Pagination from "../Pagination/Pagination"
// import { useAutoAnimate } from "@formkit/auto-animate/react"
import { sortItems } from "../common/site/utils"

const TeamListing = ({ module }) => {
  const data = useStaticQuery(graphql`
    query getTeamData {
      glstrapi {
        teams(where: {publish:true }){
          ...teamData
        }
        teamCategories {
          ...teamCategories
        }
      }
    }
  `)

  // for layout animation
  // const [parent] = useAutoAnimate({ duration: 300 })

  const { isMobile, isTablet, isLargeScreenOnly } = useDeviceMedia()

  const teamContent = module.description
  const teamData = data.glstrapi.teams?.sort((a,b)=>sortItems(a.sort_order,b.sort_order));
  const teamCategory = data.glstrapi.teamCategories

  const categoriesWithNoTeam = teamCategory.filter(
    category =>
      !teamData.find(team => team.categories[0]?.slug === category.slug)
  )

  const [filteredTeams, setFilteredTeams] = useState(teamData)
  // for pagination
  const itemsPerPage = isMobile ? 10 : isTablet || isLargeScreenOnly ? 12 : 15
  const { currentItems, currentPage, setCurrentPage } = usePagination({
    items: filteredTeams,
    itemsPerPage,
  })
  // for pagination

  const { categoryOptions, selectedCategory, handleCategoryChange } =
    useCategorySelect({
      categories: teamCategory,
      allCategoryLabel: "All Departments",
      categoriesWithNoItems: categoriesWithNoTeam,
    })

  useEffect(() => {
    if (selectedCategory.value === "all") {
      setFilteredTeams(teamData)
    } else {
      setFilteredTeams(
        teamData.filter(
          team => team.categories[0]?.slug === selectedCategory.value
        )
      )
    }
    setCurrentPage(1)
  }, [selectedCategory, teamData])

  return (
    <div className="team-list-wrapper">
      <Container className="team-listing-container">
        <div className="team-categories-section">
          <CategorySelect
            categoryOptions={categoryOptions}
            selectedCategory={selectedCategory}
            handleCategoryChange={handleCategoryChange}
            setMaxWidthForSelect
            page="team-listing"
          />
        </div>
        <div className="team-content">
          <p className="description">{parse(teamContent)}</p>
        </div>
        {currentItems.length > 0 && (
          <div className="team-listing-section" >
            {currentItems.map(team => (
              <TeamCard team={team} />
            ))}
          </div>
        )}
        <Pagination
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          totalItems={filteredTeams.length}
          setCurrentPage={setCurrentPage}
        />
      </Container>
    </div>
  )
}

export default TeamListing
