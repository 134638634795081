import React, { useState } from "react"
import { Container, Form } from "react-bootstrap"
import parse from "html-react-parser"
import "./MortgageCalculator.scss"
import { graphql, Link, useStaticQuery } from "gatsby"
import {
  calculateMonthlyPayment,
  filterNumber,
  numberFormat,
} from "../common/site/utils"
import {contactURL} from "../../site/urls"

const MortgageCalculator = ({ module }) => {
  const data = useStaticQuery(graphql`
    query getMortgageData {
      glstrapi {
        globalModule {
          mortgage_calc_image {
            url
          }
        }
      }
    }
  `)

  const [propertyPrice, setPropertyPrice] = useState(numberFormat(3687909))
  const [deposit, setDeposit] = useState(
    numberFormat(Math.round(filterNumber(propertyPrice) * 0.2))
  )
  const [mortgagePeriod, setMortgagePeriod] = useState(10)
  const [intrestRate, setIntrestRate] = useState(10)
  const [periodErr, setPeriodErr] = useState(false)
  const [depositErr, setDepositErr] = useState(false)
  const [intrestErr, setIntrestErr] = useState(false)

  const imageUrl = data.glstrapi.globalModule.mortgage_calc_image?.url
  const { description, title } = module

  const handleChange = event => {
    if (event.target.name === "property_price") {
      let val = filterNumber(event.target.value)
      setPropertyPrice(numberFormat(val))
      setDeposit(numberFormat(Math.round(val * 0.2)))
    } else if (event.target.name === "mortgage_period") {
      let duration = filterNumber(event.target.value)
      if (duration > 25 || duration < 1) {
        setPeriodErr("Mortgage period should be between 1 to 25")
        setMortgagePeriod(numberFormat(duration))
      } else {
        setMortgagePeriod(numberFormat(duration))
        setPeriodErr(false)
      }
    } else if (event.target.name === "deposit") {
      let dep = filterNumber(event.target.value)
      if (dep >= filterNumber(propertyPrice)) {
        setDepositErr(
          "Deposit should be less than property price and greater than zero"
        )
        return
      } else {
        setDepositErr(false)
        setDeposit(numberFormat(dep))
      }
    } else if (event.target.name === "intrest_rate") {
      let intrest = filterNumber(event.target.value)
      if (intrest > 20 || intrest < 1) {
        setIntrestErr("Intrest Rate should be between 1% to 20%")
        setIntrestRate(intrest)
      } else {
        setIntrestRate(intrest)
        setIntrestErr(false)
      }
    }
  }

  const loanAmount = filterNumber(propertyPrice) - filterNumber(deposit)
  const repayments = calculateMonthlyPayment(
    filterNumber(loanAmount),
    filterNumber(intrestRate),
    filterNumber(deposit),
    filterNumber(mortgagePeriod)
  )

  return (
    <div className="mortgage-calc-wrapper">
      <Container>
        <div className="mortgage-calculator-section">
          <div className="mortgage-calculator-content">
            <h2>{title}</h2>
            <div>{parse(description)}</div>
          </div>
          <div className="mortgage-calc-form">
            <Form className="mortgage-form-section">
              <Form.Group controlId="formFile" className="form-text-field">
                <Form.Label>Property Price</Form.Label>
                <Form.Control
                  type="text"
                  name="property_price"
                  value={propertyPrice}
                  onChange={e => handleChange(e)}
                />
                <span className="price-name">AED</span>
              </Form.Group>
              <Form.Group controlId="formFile" className="form-text-field">
                <Form.Label>Mortgage Period</Form.Label>
                <Form.Control
                  type="text"
                  name="mortgage_period"
                  value={mortgagePeriod}
                  onChange={e => handleChange(e)}
                />
                <span className="price-name">AED</span>
                {periodErr && <p className="mortgage-err-msg">{periodErr}</p>}
              </Form.Group>
              <Form.Group controlId="formFile" className="form-text-field">
                <Form.Label>Deposit</Form.Label>
                <Form.Control
                  type="text"
                  name="deposit"
                  value={deposit}
                  onChange={e => handleChange(e)}
                />
                <span className="price-name">AED</span>
                {depositErr && <p className="mortgage-err-msg">{depositErr}</p>}
              </Form.Group>
              <Form.Group controlId="formFile" className="form-text-field">
                <Form.Label>Interest Rate</Form.Label>
                <Form.Control
                  type="text"
                  name="intrest_rate"
                  value={intrestRate}
                  onChange={e => handleChange(e)}
                />
                <span className="price-name">AED</span>
                {intrestErr && <p className="mortgage-err-msg">{intrestErr}</p>}
              </Form.Group>
            </Form>
          </div>
          <div className="mortgage-desktop">
            <div className="mortgage-repayments">
              <p>Monthly repayments:</p>
              <h3>AED {numberFormat(Math.round(repayments))}</h3>
            </div>
            <div className="mortage-tab-section">
              <div className="pre-approved-mortgage">
                <div className="image-sec">
                  <img src={imageUrl} alt={title} />
                </div>
                <div className="pre-app-content">
                  <div className="pre-approve">Get a pre-approved mortgage in Dubai</div>
                  <p>Get started by speaking with our mortgage broker.</p>
                </div>
              </div>
              <div className="pre-approve-button">
                <Link to={contactURL} className="button button-white-outline">
                  get pre-approved
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default MortgageCalculator
