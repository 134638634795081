import { graphql, useStaticQuery } from "gatsby"
import React, { useEffect, useState } from "react"
import { Container } from "react-bootstrap"
import useCategorySelect from "../../hooks/useCategorySelect"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import usePagination from "../../hooks/usePagination"
import CategorySelect from "../CategorySelect/CategorySelect"
import NewsCard from "../NewsCard/NewsCard"
import Pagination from "../Pagination/Pagination"
// import { useAutoAnimate } from "@formkit/auto-animate/react"
import "./NewsListing.scss"
import NewsLetterBanner from "../NewsLetterBanner/NewsLetterBanner"

const NewsListing = () => {
  const data = useStaticQuery(graphql`
    query getNewsListingData {
      glstrapi {
        blogs (sort: "post_on:DESC", where: {Ghost_Page_ne:true}) {
          ...blogCardDetails
        }
        blogCategories {
          ...blogCategories
        }
      }
    }
  `)

  // for layout animation
  // const [parent] = useAutoAnimate({ duration: 300 })

  const blogs = data.glstrapi.blogs
  const blogCategories = data.glstrapi.blogCategories
  const [filteredBlogs, setFilteredBlogs] = useState(blogs)

  const { isMobile, isTablet, isLargeScreenOnly, isCustomMax } = useDeviceMedia({
    max: "1199px",
  })

  // for pagination
  const itemsPerPage = isMobile ? 10 : isTablet || isLargeScreenOnly ? 12 : 16
  const { currentItems, currentPage, setCurrentPage } = usePagination({
    items: filteredBlogs,
    itemsPerPage,
  })
  // for pagination

  // find categories with no blogs
  const categoriesWithNoBlogs = blogCategories.filter(
    category => !blogs.find(blog => blog.blog_category?.slug === category.slug)
  )

  const { categoryOptions, selectedCategory, handleCategoryChange } =
    useCategorySelect({
      categories: blogCategories,
      allCategoryLabel: "All News",
      categoriesWithNoItems: categoriesWithNoBlogs,
    })

  // Filter the blogs based on the selected category.
  useEffect(() => {
    if (selectedCategory.value === "all") {
      setFilteredBlogs(blogs)
    } else {
      setFilteredBlogs(
        blogs.filter(
          blog => blog.blog_category?.slug === selectedCategory.value
        )
      )
    }
    setCurrentPage(1)
  }, [selectedCategory, blogs])

  let value;

  if (isMobile) {
    value = 4
  } else if (isCustomMax) {
    value = 6
  } else {
    value = 8
  }

  return (
    <div className="news-listing-wrap">
      <Container className="news-listing-container">
        <div className="news-categories-section">
          <CategorySelect
            categoryOptions={categoryOptions}
            selectedCategory={selectedCategory}
            handleCategoryChange={handleCategoryChange}
            setMaxWidthForSelect
          />
        </div>
        <div className="news-listing-section" >
          {currentItems.map((blog, i) => {
            return (
              <>
                <NewsCard blog={blog} />
                  {((i + 1) % value) === 0  ?
                    <NewsLetterBanner />
                  :""}
              </>
            )})}
        </div>
        <Pagination
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          totalItems={filteredBlogs.length}
          setCurrentPage={setCurrentPage}
        />
      </Container>
    </div>
  )
}

export default NewsListing
