import clsx from "clsx"
import React, { Fragment } from "react"
import { Container } from "react-bootstrap"
import BannerStatic from "../components/BannerStatic/BannerStatic"
import FeaturedProperties from "../components/FeaturedProperties/FeaturedProperties"
import Layout from "../components/Layout/Layout"
import StaticBloackImage from "../components/StaticBloackImage/StaticBloackImage"
import StaticContactCard from "../components/StaticContactCard/StaticContactCard"
import StaticRickText from "../components/StaticRickText/StaticRickText"
import StaticStepsModule from "../components/StaticStepsModule/StaticStepsModule"
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs"
import { getWhatsAppURL } from "../components/common/site/utils"
import balckPhoneImg from "../images/black-phone.svg"
import whatsappImg from "../images/whatsapp.svg"
import useCompanyInfo from "../hooks/useCompanyInfo"
import useDeviceMedia from "../hooks/useDeviceMedia"
import MobileContact from "../components/MobileContact/MobileContact"
import LandingStructuredData from "../components/Seo/LandingStructuredData"
import { SchemaDesc } from "../site/seoschema"

const StaticTemplate = props => {
  const { GQLPage, GQLModules, locationState, pageLayout } = props
  const menuData = GQLPage.menu
  const bannerData = GQLPage.banner_section
  const { phone } = useCompanyInfo()
  const {isLargeScreen,isMobile}=useDeviceMedia()

  const isTermsPage =
    GQLPage.alias?.toLowerCase().includes("terms") ||
    GQLPage.alias?.toLowerCase().includes("policy")
    const schemaDescriptions = {
      'buyers-guide': SchemaDesc.BuyersGuide,
      'seller-guide': SchemaDesc.SellersGuide,
      'tenants-guide': SchemaDesc.TenantsGuide
    };
    const description = schemaDescriptions[GQLPage?.menu?.slug] || '';
  return (
    <Layout
      pageLayout={pageLayout}
      className={clsx(isTermsPage && "terms-page-wrap")}
      popularSearch={GQLPage?.Popular_Search}
    >
      {!isLargeScreen&&
       <div className="social-section">
          <a href={getWhatsAppURL(phone)} target="_blank">
            <img
              src={whatsappImg}
              alt="social-whatsapp"
              className="social-icon social-whatsapp"
            />
          </a>
          <a href={"tel:" + phone}>
            <img
              src={balckPhoneImg}
              alt="social-phone"
              className="social-icon social-phone"
            />
          </a>
        </div>
        }
         {
          isMobile&&
          <MobileContact/>
        }
      <Breadcrumbs menuData={menuData} />
      <BannerStatic bannerData={bannerData} />
      <LandingStructuredData description={description} />
      <Container className="static-content-wrap">
        <div className="static-content-section">
          {GQLModules.map(module => {
            return (
              <Fragment key={module.id}>
                {module.__typename === "GLSTRAPI_ComponentBlocksImage" && (
                  <StaticBloackImage module={module} />
                )}
                {module.__typename === "GLSTRAPI_ComponentBlocksRichText" && (
                  <StaticRickText module={module} />
                )}
                {module.__typename ===
                  "GLSTRAPI_ComponentModulesTextBlockCollection" && (
                  <StaticStepsModule module={module} />
                )}
              </Fragment>
            )
          })}
        </div>
        {(GQLPage.sidebar_content || GQLPage.select_contact) && (
          <div className="static-card-section">
            <StaticContactCard
              content={GQLPage.sidebar_content}
              team={GQLPage.select_contact}
              GQLPage={GQLPage}
              pageLayout={pageLayout}
            />
          </div>
        )}
      </Container>
      {GQLModules.map(module => {
        return (
          <Fragment key={module.id}>
            {module.__typename ===
              "GLSTRAPI_ComponentModulesFeaturedProperties" && (
              <FeaturedProperties module={module} />
            )}
          </Fragment>
        )
      })}
    </Layout>
  )
}

export default StaticTemplate
