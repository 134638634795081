import React from "react"
import ReactSelect from "react-select"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import downArrow from "../../images/icons/icon-down-arrow.svg"
import "./CategorySelect.scss"
import whiteArrow from "../../images/icons/white-down-arrow.svg"
import { fadeInFromTop } from "../utils/animation"

import { motion } from "framer-motion"

const CategorySelect = ({
  categoryOptions,
  selectedCategory,
  handleCategoryChange,
  setMaxWidthForSelect,
  page,
}) => {
  const { isMobile, isTabletLarge } = useDeviceMedia()

  // Get the maximum length of the category names
  const maxCategoryNameLength = Math.max(
    ...categoryOptions.map(category => category.label.length)
  )
  // Calculate the select's max width
  const selectMaxWidth = maxCategoryNameLength * 15

  return (
    <div className="category-select-wrap">
      {page === "team-listing" && isTabletLarge ? (
        <ReactSelect
          options={categoryOptions}
          isSearchable={false}
          className={"react-select category-select"}
          classNamePrefix={"react-select"}
          value={selectedCategory}
          onChange={handleCategoryChange}
          components={{
            DropdownIndicator: () => <img src={whiteArrow} className="arrow" />,
          }}
          styles={{
            control: provided => ({
              ...provided,
              maxWidth: setMaxWidthForSelect ? `${selectMaxWidth}px` : "100%",
            }),
            menu: provided => ({
              ...provided,
              maxWidth: setMaxWidthForSelect ? `${selectMaxWidth}px` : "100%",
            }),
          }}
        />
      ) : isMobile ? (
        // if mobile, render a react-select dropdown
        <ReactSelect
          options={categoryOptions}
          isSearchable={false}
          className={"react-select category-select"}
          classNamePrefix={"react-select"}
          value={selectedCategory}
          onChange={handleCategoryChange}
          components={{
            DropdownIndicator: () => <img src={downArrow} className="arrow" />,
          }}
          styles={{
            control: provided => ({
              ...provided,
              maxWidth: setMaxWidthForSelect ? `${selectMaxWidth}px` : "100%",
            }),
            menu: provided => ({
              ...provided,
              maxWidth: setMaxWidthForSelect ? `${selectMaxWidth}px` : "100%",
            }),
          }}
        />
      ) : (
        // if not mobile, render a tab section
        <div className="category-tab-section">
          {categoryOptions?.map(option => (
            <button
              className={`category-tab ${
                selectedCategory.value === option.value ? "active" : ""
              }`}
              onClick={() => handleCategoryChange(option)}
            >
              {option.label}
            </button>
          ))}
        </div>
      )}
    </div>
  )
}

export default CategorySelect
