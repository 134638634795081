import React from "react"
import "./ListingBannerNoImg.scss"
import parse from "html-react-parser"
import { Container } from "react-bootstrap"
import {
  inViewOptions2,
  containerVariants,
  fadeInFromTop,
} from "../utils/animation"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs"

const ListingBannerNoImg = ({ bannerData, page_class,GQLPage }) => {
  const { title, content } = bannerData
  const menuData = GQLPage.menu

  return (
    <InView {...inViewOptions2}>
      {({ ref, inView }) => (
        <motion.div
          className="animation-component"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}
        >
          <Breadcrumbs menuData={menuData}/>
          <>
            <motion.div
              className={`listing-banner-wrap ${page_class}`}
              variants={fadeInFromTop}
            >
              <Container className="listing-banner-container">
                <div className="banner-content">
                  <h1 className="title">{title}</h1>
                  {content && <p className="description">{parse(content)}</p>}
                </div>
              </Container>
            </motion.div>
          </>
        </motion.div>
      )}
    </InView>
  )
}

export default ListingBannerNoImg
