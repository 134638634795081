import React from "react"
import "./StaticBloackImage.scss"

const StaticBloackImage = ({ module }) => {
  if (!module.image) return null

  return (
    <div className="static-block-image-wrap">
      <img src={module.image.url} alt="static-image" className="static-img" />
    </div>
  )
}

export default StaticBloackImage
