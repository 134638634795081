import React from "react"
import googleLogo from "../../images/google.svg"
import starsImg from "../../images/stars.svg"
import reviewStarsImg from "../../images/review-with-stars.svg"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import "./GoogleReview.scss"

const GoogleReview = ({rating_avg,reviewCount}) => {
  const { isMobile } = useDeviceMedia()
  return (
    <div className="google-review-section">
      <img src={googleLogo} alt="google-logo" className="google-logo" />
      <img
        src={isMobile ? starsImg : reviewStarsImg}
        alt="stars-logo"
        className="stars-logo"
      />
      <p className="review">
        <span>{rating_avg.toFixed(1)}/5</span>
        {!isMobile && ` from ${reviewCount} Reviews`}
      </p>
    </div>
  )
}

export default GoogleReview
