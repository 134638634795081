import React, { Fragment } from "react"
import Layout from "../components/Layout/Layout"
import ContactFormComponent from "../components/ContactFormComponent/ContactFormComponent"
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs"
import { getWhatsAppURL } from "../components/common/site/utils"
import balckPhoneImg from "../images/black-phone.svg"
import whatsappImg from "../images/whatsapp.svg"
import useCompanyInfo from "../hooks/useCompanyInfo"
import useDeviceMedia from "../hooks/useDeviceMedia"
import MobileContact from "../components/MobileContact/MobileContact"
import ContactStructuredData from "../components/Seo/ContactStructuredData"
import LandingStructuredData from "../components/Seo/LandingStructuredData"
import { SchemaDesc } from "../site/seoschema"


const FormTemplate = props => {
  const {GQLPage, GQLModules, pageLayout, locationState, description } = props

  const menuData = GQLPage.menu
  const { phone } = useCompanyInfo()
  const {isLargeScreen,isMobile}=useDeviceMedia()

  const slugArray = ['book-valuation', 'list-your-property']
  let isSchemaPage = slugArray.includes(GQLPage?.menu?.slug)
  let schemaDesc;
  if (isSchemaPage) {
    schemaDesc = `Discover Allegiance: Your Premier Destination for Real Estate in Dubai. Our Expert Team is Ready to Assist You in Finding Your Dream Home, Selling Your Property, or Managing Your Investment Portfolio.`
  }
  return (
    <>
    {GQLPage?.menu?.slug === 'contact-allegiance' && <ContactStructuredData description={SchemaDesc.Contact} />}
    {isSchemaPage && <LandingStructuredData description={schemaDesc} />}
    <Layout pageLayout={pageLayout} popularSearch={GQLPage?.Popular_Search}>
      {!isLargeScreen&&
       <div className="social-section">
          <a href={getWhatsAppURL(phone)} target="_blank">
            <img
              src={whatsappImg}
              alt="social-whatsapp"
              className="social-icon social-whatsapp"
            />
          </a>
          <a href={"tel:" + phone}>
            <img
              src={balckPhoneImg}
              alt="social-phone"
              className="social-icon social-phone"
            />
          </a>
        </div>
        }

        {
          isMobile&&
          <MobileContact/>
        }
      <Breadcrumbs menuData={menuData}/>
      <div className="form-template-component">
        <div className="form-template-section">
          {GQLModules.map(module => {
            return (
              <Fragment key={module.id}>
                {module.__typename ===
                  "GLSTRAPI_ComponentComponentsSelectForm" &&
                  module.select_form === "contact" && (
                    <ContactFormComponent
                      module={module}
                      locationState={locationState}
                    />
                  )}
                {module.__typename ===
                  "GLSTRAPI_ComponentComponentsSelectForm" &&
                  module.select_form === "book_a_valuation" && (
                    <ContactFormComponent
                      module={module}
                      formName="book-a-valuation"
                      locationState={locationState}
                    />
                  )}
                {module.__typename ===
                  "GLSTRAPI_ComponentComponentsSelectForm" &&
                  module.select_form === "book_a_viewing" && (
                    <ContactFormComponent
                      module={module}
                      formName="book-a-viewing"
                      locationState={locationState}
                    />
                  )}
                {module.__typename ===
                  "GLSTRAPI_ComponentComponentsSelectForm" &&
                  module.select_form === "list_your_property" && (
                    <ContactFormComponent
                      module={module}
                      formName="list-your-property"
                      locationState={locationState}
                    />
                  )}
              </Fragment>
            )
          })}
        </div>
      </div>
    </Layout>
    </>
  )
}

export default FormTemplate
