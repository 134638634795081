import React from "react"
import NewsletterForm from "../forms/NewsletterForm"
import "./NewsLetterBanner.scss"

const NewsLetterBanner = () => {
  const title = `Stay updated with our latest newsletter.`
  const description = `Lorem ipsum dolor sit amet, consectetur adipiscing elit vestibulum.`
  return (
    <div className="newsletter-banner-wrapper">
      <div className="left-content">
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
      <div className="right-content">
        <NewsletterForm />
      </div>
    </div>
  )
}

export default NewsLetterBanner
