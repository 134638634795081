import React from "react"
import GlobalFormComponent from "../GlobalFormComponent/GlobalFormComponent"

const FormComponent = ({ module, formName, locationState }) => {
  const formData = module
  return (
    <div>
      <GlobalFormComponent
        module={formData}
        formName={formName}
        locationState={locationState}
      />
    </div>
  )
}

export default FormComponent
