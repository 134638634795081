import clsx from "clsx"
import { Link } from "gatsby"
import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import { teamsURL } from "../../site/urls"
import GetGGFXImage from "../common/site/GetGGFXImage"
import { getWhatsAppURL } from "../common/site/utils"
import IndividualTeamForm from "../forms/IndividualTeamForm"
import "./TeamCard.scss"
import {
  inViewOptions2,
  containerVariants,
  fadeInFromTop,
} from "../utils/animation"

import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"

const TeamCard = ({ team, contentLeftAlign }) => {
  const [modalShow, setModalShow] = useState(false)
  const [memberData, setMemberData] = useState("")
  const modalHandler = data => {
    setModalShow(true)
    setMemberData(data)
  }
  const closeModal = () => {
    setModalShow(false)
  }

  const ImageRender = ({ team }) => {
    let processedImages = team.imagetransforms?.tile_image_Transforms
    processedImages ||= JSON.stringify({})
    return (
      <GetGGFXImage
        imagename={"teams.tile_image.tileimg"}
        imagesource={team?.tile_image}
        fallbackalt={team.name}
        imagetransformresult={processedImages}
        id={team.id}
        className=""
      />
    )
  }

  return (
    <InView {...inViewOptions2}>
      {({ ref, inView }) => (
        <motion.div
          className="animation-component"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}
        >
          <motion.div className="team-card-wrap" variants={fadeInFromTop}>
            {team.designation === "CEO" ? (
              <Link to={`${teamsURL}${team.slug}/`}>
                <div className="image-container">
                  <ImageRender team={team} />
                </div>
              </Link>
            ) : (
              <div className="image-container">
                <ImageRender team={team} />
              </div>
            )}
            <div
              className={clsx(
                "team-card-info",
                contentLeftAlign && "content-left-align"
              )}
            >
              {team.designation === "CEO" ? (
                <Link to={`${teamsURL}${team.slug}/`}>
                  <div className="list-name">{team?.name}</div>
                </Link>
              ) : (
                <div className="list-name">{team?.name}</div>
              )}
              <div className="list-desigination">{team?.designation}</div>
              <div className="list-languages">{team?.languages}</div>
              <div className="horizontal-line"></div>
              <div className="social_icons_container">
                <a href={"tel:" + team?.mobile_no}>
                  <i className="icon phone-icon" />
                </a>
                <i
                  className="icon envelope-icon-team"
                  onClick={() => modalHandler(team)}
                />
                <a href={getWhatsAppURL(team?.mobile_no)} target="_blank">
                  <i className="icon icon-whatsup-team" />
                </a>
              </div>
            </div>
            <Modal
              show={modalShow}
              onHide={() => setModalShow(false)}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              className="more-filters-modal stay-updated team-listing-modal"
              id="filter-modal"
              // fullscreen={"xl-down"}
            >
              <button
                className="stay-button-form team-listing-form"
                type="button"
                onClick={() => closeModal()}
              >
                <i className="icon cancel-icon" />
              </button>
              <Modal.Body>
                <div className="modal-body-container">
                  <IndividualTeamForm memberData={memberData} />
                </div>
              </Modal.Body>
            </Modal>
          </motion.div>
        </motion.div>
      )}
    </InView>
  )
}

export default TeamCard
