import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import { Container } from "react-bootstrap"
import useAutoPlayVideo from "../../hooks/useAutoPlayVideo"
import { GetPageURL } from "../common/site/utils"
import GoogleReview from "../GoogleReview/GoogleReview"
import "./ListingBanner.scss"
import {
  inViewOptions2,
  containerVariants,
  fadeInFromTop,
} from "../utils/animation"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
import wordsToNumbers from 'words-to-numbers';
import axios from "axios"
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import GetGatsbyImage from "../common/site/GetGatsbyImage"

const ListingBanner = ({ bannerData, page_class, GQLPage, Header_Type }) => {
  const {
    banner_image,
    banner_video,
    title,
    title2,
    show_reviews,
    button_cta_1,
    button_cta_2,
    mobile_banner_image,
    mobile_banner_video
  } = bannerData
  const videoRef = useAutoPlayVideo()

  const menuData = GQLPage?.menu


  const [testimonials, setTestimonials] = useState([])
  const token = process.env.GATSBY_STRAPI_FORM_TOKEN

  const { isMobile } = useDeviceMedia()

  const getitems = async url => {
    try {
      const { data } = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          // "Content-Type": "multipart/form-data"
        },
      }) // could be from env files
      setTestimonials(data)
    } catch (error) {
      // cache it if fail/error;
      console.error(error)
    }
  }
  useEffect(() => {
    let url = `${process.env.GATSBY_STRAPI_SRC}/stb-google-reviews/google-review-reviews`
    getitems(url)
  }, [])

  var testimonial_count = testimonials ? testimonials.length : 0;
  var rating_count = 0;
  testimonials && testimonials.length > 0 && testimonials.map((item, ind) => {
    rating_count += parseInt(wordsToNumbers(item.starRating));
  })
  var rating_avg = rating_count / testimonial_count;
  const reviewCount = testimonials.length

  return (
    <InView {...inViewOptions2}>
      {({ ref, inView }) => (
        <motion.div
          className=""
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}
        >
          <Breadcrumbs menuData={menuData} Header_Type={Header_Type} />
          <div className={`listing-banner-wrapper ${page_class}`}>
            {isMobile && (mobile_banner_video || mobile_banner_image) ?
              <>
                {
                  mobile_banner_video ? (
                    <video
                      src={mobile_banner_video?.url}
                      alt="listing-banner-img"
                      className="listing-banner-img"
                      muted
                      ref={videoRef}
                      loop
                      playsInline
                      autoPlay
                    />
                  ) : (
                    <GetGatsbyImage
                      image={mobile_banner_image}
                      fallbackalt={"listing-banner-img"}
                      className="listing-banner-img"
                    />

                  )}

              </>
              :
              <>
                {banner_video ? (
                  <video
                    src={banner_video.url}
                    alt="listing-banner-img"
                    className="listing-banner-img"
                    // autoplay={true}
                    loop
                    muted
                    ref={videoRef}
                    playsInline
                  />
                ) : (
                  <GetGatsbyImage
                    image={banner_image}
                    fallbackalt={"listing-banner-img"}
                    className="listing-banner-img"
                  />
                )}
              </>
            }
            <div className="overlay-bg" />
            <motion.div
              className="listing-landing-container"
              variants={fadeInFromTop}
            >
              <Container className="listing-banner-content">
                <h1 className="listing-banner-title">{title}</h1>
                {button_cta_1 || button_cta_2 ? (
                  <div className="cta-section">
                    {button_cta_1 &&
                      (
                        <Link
                          to={GetPageURL(
                            button_cta_1.menu_item,
                            button_cta_1.custom_link
                          )}
                          className="button button-black"
                        >
                          {button_cta_1.label}
                        </Link>
                      )}
                    {button_cta_2 &&
                      (
                        <Link
                          to={GetPageURL(
                            button_cta_2.menu_item,
                            button_cta_2.custom_link
                          )}
                          className="button button-black"
                        >
                          {button_cta_2.label}
                        </Link>
                      )}
                  </div>
                ) : (
                  ""
                )}
                {show_reviews && <GoogleReview rating_avg={rating_avg} reviewCount={reviewCount} />}
              </Container>
            </motion.div>
          </div>
        </motion.div>
      )}
    </InView>
  )
}

export default ListingBanner
