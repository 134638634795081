import { graphql, navigate } from "gatsby"
import React from "react"
import "../scss/global.scss"
import CommonTemplate from "./common-template"
import FormTemplate from "./form-template"
import StaticTemplate from "./static-template"
import SEO from "../components/Seo/seo"

const DefaultTemplate = props => {
  const { data, location } = props

  const GQLPage = data?.glstrapi?.page
  const GQLModules = GQLPage?.add_components
  const locationState = location.state
  const pageLayout = GQLPage.layout
  const Header_Type = GQLPage.Header_Type
  const page_class = GQLPage.page_class

  if (!GQLPage.publish) navigate("/404")

  return (
    <>
      <SEO
        title={GQLPage.seo_meta ? GQLPage.seo_meta.title : GQLPage.title}
        description={GQLPage.seo_meta ? GQLPage.seo_meta.description : ""}
      />
      {(pageLayout === "landing_template" ||
        pageLayout === "listing_template") && (
        <CommonTemplate
          GQLPage={GQLPage}
          GQLModules={GQLModules}
          locationState={locationState}
          pageLayout={pageLayout}
          Header_Type={Header_Type}
          page_class={page_class}
        />
      )}
      {pageLayout === "static_template" && (
        <StaticTemplate
          GQLPage={GQLPage}
          GQLModules={GQLModules}
          locationState={locationState}
          pageLayout={pageLayout}
        />
      )}
      {pageLayout === "form_template" && (
        <FormTemplate
          GQLPage={GQLPage}
          pageLayout={pageLayout}
          GQLModules={GQLModules}
          locationState={locationState}
          description={GQLPage.seo_meta ? GQLPage.seo_meta.description : ""}
        />
      )}
    </>
  )
}

export default DefaultTemplate

export const pageQuery = graphql`
  query GetPage($id: ID!) {
    glstrapi {
      page(id: $id) {
        seo_meta {
          ...SeoMeta
        }
        page_class
        layout
        Header_Type
        publish
        alias
        choose_form
        content
        id
        title
        page_class
        Popular_Search
        publish
        menu {
          ...menuData
        }
        banner_section {
          mobile_banner_image{
            url
            url_sharp {
              id
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          banner_image {
            url
            url_sharp {
              id
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          banner_video {
            url
          }
          mobile_banner_video {
            url
          }
          content
          id
          show_reviews
          show_search
          title
          title2
          button_cta_1 {
            ...buttonCtaData
          }
          button_cta_2 {
            ...buttonCtaData
          }
        }
        select_contact {
          ...team
        }
        add_components {
          ... on GLSTRAPI_ComponentComponentsSelectCollection {
            ...selectCollectionData
          }

          ... on GLSTRAPI_ComponentComponentsSelectModules {
            __typename
            id
            select_module
          }
          ... on GLSTRAPI_ComponentModulesStatsModule {
            __typename
            id
            stats {
              heading
              icon_class
              content
              id
            }
            title
          }

          ... on GLSTRAPI_ComponentModulesTextContentsSlider {
            __typename
            id
            slider_content {
              description
              image {
                url
              }
              id
              title
            }
            title
            description
          }

          ... on GLSTRAPI_ComponentModulesGuideDownloadModule {
            ...GuideDownloadModule
          }
          ... on GLSTRAPI_ComponentModulesContentWithStats {
            __typename
            id
            title
            content_stat_theme
            stats {
              stat
              id
              description
            }
            select_collection {
              ...selectCollectionData
            }
            description
            cta {
              ...buttonCtaData
            }
          }
          ... on GLSTRAPI_ComponentModulesFeaturedProperties {
            ...FeaturedProperties
          }

          ... on GLSTRAPI_ComponentBlocksRichText {
            __typename
            id
            text
          }

          ... on GLSTRAPI_ComponentBlocksImage {
            __typename
            id
            image {
              url
            }
          }

          ... on GLSTRAPI_ComponentModulesTextBlockCollection {
            __typename
            id
            contents {
              title
              id
              description
            }
          }
          ... on GLSTRAPI_ComponentComponentsSelectForm {
            __typename
            id
            select_form
            title
            description
          }

          ... on GLSTRAPI_ComponentComponentsFaq {
            __typename
            title
            faq {
              question
              answer
            }
          }
        }
        sidebar_content {
          title
          description
        }
      }
    }
  }
`
