import React from "react"
import { Container } from "react-bootstrap"
import parse from "html-react-parser"
import "./JobOpenings.scss"
import { graphql, Link, useStaticQuery } from "gatsby"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import {
  inViewOptions2,
  containerVariants,
  fadeInFromTop,
} from "../utils/animation"

import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"

const JobOpenings = ({ module }) => {
  const data = useStaticQuery(graphql`
    query getJobData {
      glstrapi {
        jobOppenings {
          ...jobOppeningsData
        }
      }
    }
  `)
  const { description, theme, title } = module

  const jobList = data.glstrapi.jobOppenings
  const { isCustomMin } = useDeviceMedia({ min: "1280px" })
  const { isMobile } = useDeviceMedia()

  return (
    <InView {...inViewOptions2}>
      {({ ref, inView }) => (
        <motion.div
          className="animation-component"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}
        >
          <motion.div
            className={`job-opening-wrapper ${theme}`}
            id="job-oppening-component"
            variants={fadeInFromTop}
          >
            <Container>
              <div className="job-section">
                <h2 className="job-component-title">{title}</h2>
                <div className="job-component-description">
                  {parse(description)}
                </div>
                {jobList.length > 0 && (
                  <div className="job-list-section">
                    {jobList.map(job => (
                      <div className="available-job-list">
                        <h3 className="job-title">{job.Job_name}</h3>
                        {!isMobile && (
                          <p className="job-role">{job.Designation}</p>
                        )}
                        {isCustomMin && <p className="job-ctc">{job.Ctc}</p>}
                        <div className="link-job-details">
                          <Link to={`${job.slug}`}>
                            <span className="view">View Details</span>
                            <span className="icon-view-details">
                              <i className="icon white-arrow-up-right" />
                            </span>
                          </Link>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </Container>
          </motion.div>
        </motion.div>
      )}
    </InView>
  )
}

export default JobOpenings
