// import { useAutoAnimate } from "@formkit/auto-animate/react"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Container } from "react-bootstrap"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import usePagination from "../../hooks/usePagination"
import AreaguideCard from "../AreaguideCard/AreaguideCard"
import { sortItems } from "../common/site/utils"
import Pagination from "../Pagination/Pagination"
import "./AreaGuideListing.scss"

const AreaGuideListing = () => {
  const data = useStaticQuery(graphql`
    query getAreaguideData {
      glstrapi {
        areaGuides(where: {publish:true }) {
          ...areaguideDetails
        }
      }
    }
  `)

  const areaguides = data.glstrapi.areaGuides?.sort((a,b)=>sortItems(a.sort_order,b.sort_order));

  // const [parent] = useAutoAnimate({ duration: 300 })
  const { isMobile, isTablet, isLargeScreenOnly } = useDeviceMedia()

  const itemsPerPage = isMobile ? 10 : isTablet || isLargeScreenOnly ? 12 : 16
  const { currentItems, currentPage, setCurrentPage } = usePagination({
    items: areaguides,
    itemsPerPage,
  })
  return (
    <div className="areaguide-listing-warapper">
      <Container>
        {currentItems.length > 0 && (
          <div className="areaguide-list-section" >
            {currentItems.map(areaguide => (
              <AreaguideCard areaguide={areaguide} />
            ))}
          </div>
        )}
        <Pagination
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          totalItems={areaguides.length}
          setCurrentPage={setCurrentPage}
        />
      </Container>
    </div>
  )
}

export default AreaGuideListing
